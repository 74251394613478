import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';



export default function Maps(props) {
  const position = [-7.247573, -35.877063]

  return (
    <div>
      <MapContainer center={position} zoom={16}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} >
          <Popup>
            Residencial Linear<br/>R. Dr. Wilson Furtado, 189 - Sandra Cavalcante
          </Popup>
        </Marker>
      </MapContainer>

    </div>
  )
}
