import React from 'react'
import Modelviewer from '../components/modelviewer'

export default function Home(props) {

    return (
        <div>
           <Modelviewer /> 
        </div>
    )
}
