import React from 'react';
import '@google/model-viewer';
import '../App.css'
import { Button } from '@material-ui/core';
import { Helmet } from "react-helmet";
import ModelGlt from '../assets/building1.glb'
import ModelUsdz from '../assets/building.usdz'
import Poster3D from '../assets/loadingscreen.png'
import Environment from '../assets/street.hdr';
import logoLinear from '../assets/logoLinear.jpg';

export default function modelviewer() {


    return (
        <div>
            <Helmet>
                <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
            </Helmet>

            <model-viewer
                camera-controls autoplay ar ar-modes="scene-viewer webxr quick-look"
                src={ModelGlt}
                ios-src={ModelUsdz}
                environment-image={Environment}
                poster={Poster3D}
                loading="eager" 
                
            >
                <Button slot="ar-button" id="ar-button">
                    Visualize em seu ambiente
                </Button>
            </model-viewer>
            
        </div>
    )
}