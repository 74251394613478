import React from 'react';
import '@google/model-viewer';
import '../App.css'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'


import Foto1 from '../assets/galeria/01.png';
import Foto1_s from '../assets/galeria/01_s.png';
import Foto2 from '../assets/galeria/02.png';
import Foto2_s from '../assets/galeria/02_s.png';
import Foto3 from '../assets/galeria/03.png';
import Foto3_s from '../assets/galeria/03_s.png';
import Foto4 from '../assets/galeria/04.png';
import Foto4_s from '../assets/galeria/04_s.png';
import Foto5 from '../assets/galeria/05.png';
import Foto5_s from '../assets/galeria/05_s.png';
import Foto6 from '../assets/galeria/06.png';
import Foto6_s from '../assets/galeria/06_s.png';
import Foto7 from '../assets/galeria/07.png';
import Foto7_s from '../assets/galeria/07_s.png';
import Foto8 from '../assets/galeria/08.png';
import Foto8_s from '../assets/galeria/08_s.png';

export default function Interior() {
    return (
        <div>
            <div style={{ backgroundColor: '#3493F6', color: '#fff', padding: '5px 0', width: '100%' }}>
                <h3 >Detalhes do Empreendimento</h3>
            </div>
            <p style={{fontSize:'11pt', color:'#b0b0b0'}}>Imagens meramente ilustrativas</p>

            <div className="GaleriaContainer">

                <Gallery>
                    <Item
                        original={Foto1}
                        thumbnail={Foto1_s}
                        width="800"
                        height="800"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto1_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto2}
                        thumbnail={Foto2_s}
                        width="600"
                        height="800"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto2_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto3}
                        thumbnail={Foto3_s}
                        width="1200"
                        height="670"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto3_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto4}
                        thumbnail={Foto4_s}
                        width="1200"
                        height="670"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto4_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto5}
                        thumbnail={Foto5_s}
                        width="1200"
                        height="337"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto5_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto6}
                        thumbnail={Foto6_s}
                        width="1200"
                        height="670"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto6_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto7}
                        thumbnail={Foto7_s}
                        width="1200"
                        height="670"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto7_s} />
                        )}
                    </Item>
                    <Item
                        original={Foto8}
                        thumbnail={Foto8_s}
                        width="1200"
                        height="337"
                    >
                        {({ ref, open }) => (
                            <img className="fotoGaleria" ref={ref} onClick={open} src={Foto8_s} />
                        )}
                    </Item>

                </Gallery>

            </div>
        </div>
    )
}